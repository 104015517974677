import { MAX_WIDTH } from '@layouts/grid';

export * from './date';

export const generateVwPercentageHeight = (
    height,
    maxHeight,
    parent = MAX_WIDTH,
) => `
    height: ${(height / parent) * 100}vw;
    max-height: ${maxHeight || height}px;
`;

export const generateVwPercentageWidth = (
    width,
    maxWidth,
    parent = MAX_WIDTH,
) => `
    width: ${(width / parent) * 100}vw;
    max-width: ${maxWidth || width}px;
`;
