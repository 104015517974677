import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy';
const MOBILE_DATE_FORMAT = 'dd.MM';
const DATE_HEADER_FORMAT = 'iiii MMMM do';
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_ISO_DATE_FORMAT = 'yyyy-MM-dd';

const parseDate = dateString => parseISO(dateString);

export const formatDate = (date, dformat = DEFAULT_DATE_FORMAT) => {
    if (date) {
        return format(parseDate(date), dformat);
    }
    return '';
};

export const formatDateHeader = date => {
    if (date) {
        const dateHeader = format(parseDate(date), DATE_HEADER_FORMAT);
        return [
            dateHeader.substr(0, dateHeader.length - 2),
            dateHeader.substr(dateHeader.length - 2),
        ];
    }
    return '';
};

export const formatTime = date => formatDate(date, DEFAULT_TIME_FORMAT);

export const formatDateMobile = date => formatDate(date, MOBILE_DATE_FORMAT);

export const formatGroupDate = date =>
    formatDate(date, DEFAULT_ISO_DATE_FORMAT);
